import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {
  Loader,
  Icon
} from 'semantic-ui-react';
import classNames from 'classnames';
import './Home.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { State } from 'store/reducers';
import api from 'api';
import { FETCH_CURRENT_USER } from 'store/actions/actionTypes';
import { User } from 'models/User';
import { capitalize } from 'utils/string';
import credentialsService from 'services/credentialsService';
import CheckRole from 'components/CheckRole';

const HomePage: React.FC<{}> = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector<State, User>((state) => state.currentUser);
  const [hover, setHover] = useState(false);

  const classes = classNames(['lgu-home-page'])
  const logOut = useCallback(() => {
    credentialsService.removeAuthBody();
    dispatch({ type: FETCH_CURRENT_USER, payload: null });
    history.push('/login');
  }, [dispatch, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const loadUser = async () => {
      const { data: user } = await api.users.getCurrent();
      dispatch({ type: FETCH_CURRENT_USER, payload: user });
    };

    if (!user) {
      loadUser();
    }
  }, [dispatch, user, location]);

  if (!user) {
    return <Loader active />;
  }

  return (
    <div className={classes}>
      <div
        className="lgu-home-page__sidebar"
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Link to="/home/landing">
          <Icon size="large" name="wechat" />
          New chat
        </Link>
        <Link to="/home/chat-history">
          <Icon size="large" name="history" />
          Chat History
        </Link>
        <CheckRole role={'Admin'}>
          <Link to="/home/files">
            <Icon size="large" name="file" />
            Files
          </Link>
        </CheckRole>
        <CheckRole role={'Admin'}>
          <Link to="/home/users">
            <Icon size="large" name="users" />
            Users
          </Link>
        </CheckRole>
        <CheckRole role={'Admin'}>
          <Link to="/home/feedbacks">
            <Icon size="large" name="book" />
            Feedbacks
          </Link>
        </CheckRole>
      </div>
      <nav className='lgu-home-page__nav'>
          <Link to="/home/profile">{capitalize(user.name)}</Link>
          <Link to="/login" onClick={logOut}>Log out</Link>
      </nav>
      <div className={hover ? 'lgu-home-page__routes--hover' : 'lgu-home-page__routes'}>
        {children}
      </div>
    </div>
  );
};

export default HomePage;
