import React, { useState } from 'react';
import './ConfirmPopup.styles.scss';
import { Popup, Button } from 'semantic-ui-react';

type ConfirmPopupProps = {
  initialOpen?: boolean;
  text: string;
  buttonText?: string;
  confirm: Function;
  position?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center';
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge';
  pinned?: boolean;
  inverted?: boolean;
};

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
  text,
  confirm,
  buttonText = 'Confirm',
  position = 'top right',
  size = 'small',
  pinned,
  inverted,
  initialOpen,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  return (
    <Popup
      trigger={React.cloneElement(children as any, {
        onClick: (ev: any) => {
          ev.stopPropagation();
          setIsOpen(true);
        },
      })}
      header={text}
      content={
        <Button
          floated="right"
          onClick={(ev) => {
            ev.stopPropagation();
            confirm();
          }}
        >
          {buttonText}
        </Button>
      }
      position={position}
      size={size}
      on="click"
      pinned={pinned}
      inverted={inverted}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    />
  );
};

export default ConfirmPopup;
