import React, { useCallback, useState, useEffect } from 'react';
import {
  Table,
  Pagination,
  Loader,
  Button,
  Input,
  Segment,
  Header,
} from 'semantic-ui-react';
import './FilesTable.styles.scss';
import usePagination from 'hooks/usePagination';
import api from 'api';
import FileModal from './components/FileModal';
import { File } from 'models/File';
import ConfirmPopup from 'components/ConfirmPopup';
import { toast } from 'react-toastify';
import DatePreview from 'components/DatePreview';

type FilesTableProps = {};

const FilesTable: React.FC<FilesTableProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const { items, loadItems, setItems, currentPage, totalPages } =
    usePagination<File>(api.files.get, { clearOnLoad: false });

  useEffect(() => {
    loadItems(1, { $searchString: searchInput });
  }, [searchInput, loadItems]);

  const onCreate = useCallback(
    async (formData) => {
      setIsLoading(true);
      try {
        if (formData.get('files')) {
          const { data: files } = await api.files.createMultipleFiles(formData);
          setItems((items) => [...files, ...items]);
          toast.success(
            `Successfully created ${formData.getAll('files').length} files.`,
          );
        } else {
          const { data: file } = await api.files.create(formData);
          setItems((items) => [file, ...items]);
          toast.success('Successfully created file.');
        }
      } catch {
        toast.error('Error occured while creating file');
      } finally {
        setIsLoading(false);
      }
    },
    [setItems],
  );

  const onUpdate = useCallback(
    async (formData: FormData, changedFile: File) => {
      setIsLoading(true);
      try {
        const { data: file } = await api.files.replaceOne(
          changedFile._id,
          formData,
        );
        setItems((items) =>
          items.map((item) => (item._id === changedFile._id ? file : item)),
        );
        toast.success('Successfully updated file.');
      } finally {
        setIsLoading(false);
      }
    },
    [setItems],
  );

  const onDelete = useCallback(
    async (file: File) => {
      setIsLoading(true);
      try {
        await api.files.deleteOne(file._id);
        setItems((items) => items.filter((item) => item._id !== file._id));
        toast.success('Successfully deleted file.');
      } finally {
        setIsLoading(false);
      }
    },
    [setItems],
  );

  if (!items || isLoading) {
    return <Loader active />;
  }

  return (
    <Segment>
      <Header>Files</Header>
      <Input
        focus
        placeholder="Search..."
        value={searchInput}
        onChange={(e, field) => setSearchInput(field.value)}
      />
      <FileModal onSubmit={onCreate} action="create">
        <Button floated="right">Create File</Button>
      </FileModal>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Is processed</Table.HeaderCell>
            <Table.HeaderCell>Type/Sector</Table.HeaderCell>
            <Table.HeaderCell>Version</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((file: File) => (
            <Table.Row key={file._id}>
              <Table.Cell>{file.name}</Table.Cell>
              <Table.Cell>{file.isProcessed ? 'Yes' : 'No'}</Table.Cell>
              <Table.Cell>{file.sector}</Table.Cell>
              <Table.Cell>{file.revision}</Table.Cell>
              <Table.Cell>
                <DatePreview>{file.createdAt}</DatePreview>
              </Table.Cell>
              <Table.Cell>
                <FileModal action="update" onSubmit={onUpdate} file={file}>
                  <Button icon="edit" color="blue"></Button>
                </FileModal>
                <ConfirmPopup
                  confirm={() => onDelete(file)}
                  text="Are you sure you want to remove this file?"
                  buttonText="Delete"
                >
                  <Button icon="trash" color="red"></Button>
                </ConfirmPopup>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="6">
              <Pagination
                floated="right"
                activePage={currentPage}
                onPageChange={(ev, { activePage }) => loadItems(activePage)}
                totalPages={totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

export default FilesTable;
