import httpClient from '../privateClient';
import { PaginationResponse } from '../../models/PaginationResponse';
import questions from './questions';
import { ChatHistory } from 'models/ChatHistory';

async function get(skip: number, take: number, additionalParams = {}) {
  return httpClient.get<PaginationResponse<ChatHistory>>('chat-history', {
    params: {
      $skip: skip,
      $limit: take,
      ...additionalParams,
      $populate: ['previousQuestion'],
    },
  });
}

async function deleteOne(id: string) {
  return httpClient.delete<{ deleted: boolean }>(`chat-history/${id}`);
}

export default {
  get,
  deleteOne,
  questions,
};
