export const CHAT_MODELS = [
  'gpt-3.5-turbo-0613',
  'gpt-3.5-turbo-16k',
  'gpt-4',
] as const;

export const DEFAULT_CHAT_MODEL: (typeof CHAT_MODELS)[number] =
  'gpt-3.5-turbo-16k';

export const CHAT_MODEL_LIMITS: {
  [T in (typeof CHAT_MODELS)[number]]: string;
} = {
  'gpt-3.5-turbo-0613': '4K',
  'gpt-4': '8k',
  'gpt-3.5-turbo-16k': '16k',
};
