export type Sectors =
  | 'general legislations'
  | '124'
  | '200'
  | '201'
  | '302'
  | '323';

export const SECTORS: Array<{ text: string; value: Sectors }> = [
  {
    text: 'General legislations',
    value: 'general legislations',
  },
  {
    text: 'Sector 124',
    value: '124',
  },
  {
    text: 'Sector 200',
    value: '200',
  },
  {
    text: 'Sector 201',
    value: '201',
  },
  {
    text: 'Sector 302',
    value: '302',
  },
  {
    text: 'Sector 323',
    value: '323',
  },
];
