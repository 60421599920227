import httpClient from '../privateClient';
import { PaginationResponse } from '../../models/PaginationResponse';
import { File } from 'models/File';

async function create(body: FormData) {
  return httpClient.post<File>('files', body);
}

async function createMultipleFiles(body: FormData) {
  return httpClient.post<File[]>('files/bulk', body);
}

async function replaceOne(id: string, body: FormData) {
  return httpClient.put<File>(`files/${id}`, body);
}

async function get(skip: number, take: number, additionalParams = {}) {
  return httpClient.get<PaginationResponse<File>>('files', {
    params: {
      $skip: skip,
      $limit: take,
      ...additionalParams,
    },
  });
}

async function deleteOne(id: string) {
  return httpClient.delete<{ deleted: boolean }>(`files/${id}`);
}

export default {
  create,
  get,
  replaceOne,
  deleteOne,
  createMultipleFiles,
};
