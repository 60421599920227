import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Pagination,
  Loader,
  Button,
  Input,
  Segment,
  Header,
} from 'semantic-ui-react';
import './ChatHistoryTable.styles.scss';
import usePagination from 'hooks/usePagination';
import api from 'api';
import DatePreview from 'components/DatePreview';
import { useHistory } from 'react-router';
import { ChatHistory } from 'models/ChatHistory';
import ConfirmPopup from 'components/ConfirmPopup';
import { toast } from 'react-toastify';

type ChatHistoryTableProps = {};

const ChatHistoryTable: React.FC<ChatHistoryTableProps> = (props) => {
  const history = useHistory();

  const [searchInput, setSearchInput] = useState('');
  const { items, loadItems, setItems, currentPage, totalPages } =
    usePagination<ChatHistory>(api.chatHistory.get, { clearOnLoad: false });

  useEffect(() => {
    loadItems(1, { $searchString: searchInput });
  }, [searchInput, loadItems]);

  const onDelete = useCallback(
    async (chatHistory: ChatHistory) => {
      await api.chatHistory.deleteOne(chatHistory._id);
      setItems((items) => items.filter((item) => item._id !== chatHistory._id));
      toast.success('Successfully deleted file.');
    },
    [setItems],
  );

  if (!items) {
    return <Loader active />;
  }

  return (
    <Segment>
      <Header>Chat History</Header>
      <Input
        focus
        placeholder="Search..."
        value={searchInput}
        onChange={(e, field) => setSearchInput(field.value)}
      />
      <Button floated="right" onClick={() => history.push('/home/landing')}>
        New Chat
      </Button>
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Question</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((chatHistory: ChatHistory) => (
            <Table.Row
              key={chatHistory._id}
              className='chat-history-row'
              onClick={() => {
                history.push(`chat-history/${chatHistory._id}`);
              }}
            >
              <Table.Cell>{chatHistory.previousQuestion.question}</Table.Cell>
              <Table.Cell>
                <DatePreview>{chatHistory.createdAt}</DatePreview>
              </Table.Cell>
              <Table.Cell>
                <ConfirmPopup
                  confirm={() => onDelete(chatHistory)}
                  text="Are you sure you want to remove this chat history?"
                  buttonText="Delete"
                >
                  <Button icon="trash" color="red"></Button>
                </ConfirmPopup>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="3">
              <Pagination
                floated="right"
                activePage={currentPage}
                onPageChange={(ev, { activePage }) => loadItems(activePage)}
                totalPages={totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

export default ChatHistoryTable;
