import React from 'react';
import './CheckRole.styles.scss';
import { Role } from 'models/Roles';
import useHasRole from 'hooks/useHasRole';

type CheckRoleProps = {
  children: any;
  fallback?: React.ReactNode;
  role: Role;
};

const CheckRole = ({
  children,
  role: permission,
  fallback = null,
}: CheckRoleProps) => {
  const hasPermission = useHasRole(permission);

  if (!hasPermission) {
    return fallback;
  }
  return children;
};

export default CheckRole;
