import httpClient from '../privateClient';
import { User } from '../../models/User';
import { PaginationResponse } from '../../models/PaginationResponse';

type CreateBody = {
  name: string;
  email: string;
  password: string;
};

async function create(body: CreateBody) {
  return httpClient.post<User>('users', body);
}

async function get(skip: number, take: number, additionalParams = {}) {
  return httpClient.get<PaginationResponse<User>>('users', {
    params: {
      $skip: skip,
      $limit: take,
      ...additionalParams,
    },
  });
}

async function getCurrent() {
  return httpClient.get<User>('users/current');
}

async function updateOne(id: string, body: Partial<CreateBody> | FormData) {
  return httpClient.patch<User>(`users/${id}`, body);
}

async function deleteOne(id: string) {
  return httpClient.delete<{ deleted: boolean }>(`users/${id}`);
}

export default {
  create,
  get,
  getCurrent,
  updateOne,
  deleteOne,
};
