import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import routes from 'router/routes';
import Routes from 'router/components/Routes/Routes';
import { toast } from 'react-toastify';

function App() {
  useEffect(() => {
    toast.configure({
      autoClose: 2000,
      limit: 3,
      position: 'bottom-right',
      hideProgressBar: true,
    });
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes routes={routes} />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
