import httpClient from '../publicClient';
import { AuthBody } from '../../models/AuthBody';

async function login(userData: { email: string; password: string }) {
  return httpClient.post<AuthBody>('users/auth/login', userData);
}

export default {
  login,
};
