import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Routes from 'router/components/Routes';
import routes from './routes';
import HomePage from 'pages/Home';

const HomeRouter: React.FC<RouteComponentProps> = () => {
  return (
    <HomePage>
      <Routes routes={routes} />
    </HomePage>
  );
};

export default HomeRouter;
