import React, { useCallback, useState } from 'react';
import './UserProfile.styles.scss';
import { Segment, Header, Form, Button } from 'semantic-ui-react';
import api from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store/reducers';
import { User } from 'models/User';
import { toast } from 'react-toastify';
import { UPDATE_CURRENT_USER } from 'store/actions/actionTypes';

type UserProfileProps = {};

const UserProfile: React.FC<UserProfileProps> = (props) => {
  const dispatch = useDispatch();
  const user = useSelector<State, User>((state) => state.currentUser);

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState(user.password);

  const onChange = useCallback(
    async (ev) => {
      try {
        const { data: user } = await api.users.updateOne('current', {
          name,
          email,
          password,
        });
        toast.success('Updated current user');
        dispatch({ type: UPDATE_CURRENT_USER, payload: user });
      } catch (err) {
        console.log(err);
      }
    },
    [dispatch, name, password, email],
  );

  return (
    <Segment>
      <Header>User profile</Header>
      <Form onSubmit={onChange}>
        <Form.Input
          fluid
          label="Name"
          value={name}
          onChange={(ev, { value }) => setName(value)}
          required
        />
        <Form.Input
          fluid
          label="Email"
          value={email}
          onChange={(ev, { value }) => setEmail(value)}
          required
        />
        <Form.Input
          fluid
          type="password"
          label="Password"
          value={password}
          onChange={(ev, { value }) => setPassword(value)}
        />
        <Button type="submit">Update</Button>
      </Form>
    </Segment>
  );
};

export default UserProfile;
