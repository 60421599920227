import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { PaginationResponse } from '../models/PaginationResponse';

const PAGINATION_DEFAULT_LIMIT = 10;

type GetRequest<T> = (
  skip: number,
  take: number,
  additionalParams?: Record<string, any>,
) => Promise<AxiosResponse<PaginationResponse<T>>>;

type Options = {
  take?: number;
  clearOnLoad?: boolean;
  appendItems?: boolean;
};

export default function usePagination<T>(
  getRequest: GetRequest<T>,
  options: Options = {},
) {
  const {
    take = PAGINATION_DEFAULT_LIMIT,
    clearOnLoad = true,
    appendItems = false,
  } = options;

  const [items, setItems] = useState<T[]>(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const loadItems = useCallback(
    async (newPage, additionalData = {}) => {
      const page = newPage;
      setCurrentPage(page);
      if (clearOnLoad) {
        setItems(null);
      }
      try {
        const {
          data: { items, totalItems: itemsTotal, totalPages },
        } = await getRequest((page - 1) * take, take, additionalData);
        setItems((oldItems) =>
          oldItems && appendItems ? [...oldItems, ...items] : items,
        );
        setTotalPages(totalPages);
        setTotalItems(itemsTotal);
      } catch (err) {
        console.log(err);
      }
    },
    [
      setCurrentPage,
      setTotalPages,
      setItems,
      getRequest,
      take,
      clearOnLoad,
      appendItems,
    ],
  );

  return { items, totalItems, totalPages, currentPage, loadItems, setItems };
}
