import { Question } from 'models/Question';
import httpClient from '../privateClient';
import { ChatHistory } from 'models/ChatHistory';
import { PaginationResponse } from 'models/PaginationResponse';
import { Feedback } from 'models/Feedback';
import { FeedbackBody } from 'models/FeedbackBody';
import { Sectors } from 'constants/sectors';

type CreateBody = {
  question: string;
  chatHistoryId?: string;
  chatModel?: string;
  sector?: Sectors;
};

async function create(body: CreateBody) {
  return httpClient.post<{ question: Question; chatHistory: ChatHistory }>(
    'questions',
    body,
  );
}

async function get(skip: number, take: number, additionalParams = {}) {
  return httpClient.get<PaginationResponse<Question>>('/questions', {
    params: {
      $skip: skip,
      $limit: take,
      ...additionalParams,
      $populate: ['feedback'],
    },
  });
}

async function createFeedback(body: FeedbackBody, questionId: string) {
  return httpClient.post(`question-feedbacks/${questionId}`, body);
}

async function getFeedbacks(skip: number, take: number, additionalParams = {}) {
  return httpClient.get<PaginationResponse<Feedback>>(`question-feedbacks`, {
    params: {
      $skip: skip,
      $limit: take,
      ...additionalParams,
      $populate: ['question'],
    },
  });
}

export default {
  create,
  get,
  createFeedback,
  getFeedbacks,
};
