import React from 'react';
import './NotAuthorized.styles.scss';
import { Container, Header, Icon } from 'semantic-ui-react';

type NotAuthorizedProps = {
  text?: string;
};

const NotAuthorized: React.FC<NotAuthorizedProps> = ({
  text = "You shouldn't be here, you have no access to this resource!",
}) => {
  return (
    <Container>
      <Header textAlign="center">
        <Icon name="blind" />
        {text}
      </Header>
    </Container>
  );
};

export default NotAuthorized;
