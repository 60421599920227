import React, { useEffect } from 'react';
import { Segment, Loader, Item, Header } from 'semantic-ui-react';
import { useRouteMatch, useLocation } from 'react-router';
import { Question } from 'models/Question';
import usePagination from 'hooks/usePagination';
import QuestionForm from 'components/QuestionForm';
import FeedbackForm from 'components/FeedbackForm';
import { Feedback } from 'models/Feedback';
import classNames from 'classnames';
import api from 'api';
import './Chat.styles.scss';

type ChatProps = {};

const Chat: React.FC<ChatProps> = () => {
  const classes = classNames(['lgu-chat']);
  const location = useLocation<Feedback>();

  const questionFeedback = location.state;
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { items, loadItems, setItems } = usePagination<Question>(
    api.chatHistory.questions.get,
    {
      clearOnLoad: false,
      appendItems: true,
    },
  );

  useEffect(() => {
    setItems(null);
    loadItems(1, { chatHistoryId: id });
  }, [id, setItems, loadItems]);

  const updateItems = (newItems: Question[]) => {
    setItems(newItems);
  };

  const onHandleSubmit = async (data: { question: Question }) => {
    setItems((old) => [data.question, ...old]);
  };

  const singleFeedback = (key: string) => {
    return items.find((element: Question) => {
      return key === element.feedback?.questionId;
    })?.feedback;
  };

  if (!items) {
    return <Loader active />;
  }

  return (
    <div className={classes}>
      <Segment padded="very" raised className="lgu-chat__section">
        <QuestionForm chatHistoryId={id} onSubmit={onHandleSubmit} />
      </Segment>
      {items.map((question: Question) => {
        if (questionFeedback) {
          document
            .getElementById(`${questionFeedback.questionId}`)
            ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        return (
          <Segment
            padded="very"
            id={question._id === questionFeedback?.questionId && question._id}
            className={
              questionFeedback?.questionId === question._id
                ? 'lgu-chat__section--active'
                : 'lgu-chat__section'
            }
          >
            <Header as="h4">Question:</Header>
            <Item>{question.question}</Item>
            <Header as="h4">Answer:</Header>
            <Item className="lgu-chat__section--answer">{question.answer}</Item>
            <FeedbackForm
              questionId={question._id}
              items={items}
              feedback={singleFeedback(question._id)}
              onSubmit={updateItems}
            />
          </Segment>
        );
      })}
    </div>
  );
};

export default Chat;
