import { combineReducers } from 'redux';
import currentUserReducer from './auth';
import { User } from '../../models/User';

export default combineReducers({
  currentUser: currentUserReducer,
});

export type State = {
  currentUser: User | null;
};
