import {
  FETCH_CURRENT_USER,
  UPDATE_CURRENT_USER,
} from '../actions/actionTypes';
import { User } from 'models/User';

const currentUserReducer = (state: User = null, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CURRENT_USER:
      return payload;
    case UPDATE_CURRENT_USER:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default currentUserReducer;
