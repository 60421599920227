import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import redirect from 'router/modules/redirect';
import UsersTable from 'router/subrouters/Home/pages/UsersTable';
import UserProfile from 'router/subrouters/Home/pages/UserProfile';
import FilesTable from 'router/subrouters/Home/pages/FilesTable';
import ChatHistoryTable from 'router/subrouters/Home/pages/ChatHistoryTable';
import Landing from 'router/subrouters/Home/pages/Landing';
import Chat from 'router/subrouters/Home/pages/Chat';
import FeedbacksTable from 'router/subrouters/Home/pages/FeedbacksTable';

export default [
  {
    path: '/profile',
    component: UserProfile,
    exact: true,
  },
  {
    path: '/landing',
    component: Landing,
    exact: true,
  },
  {
    path: '/chat-history',
    component: ChatHistoryTable,
    exact: true,
  },
  {
    path: '/chat-history/:id',
    component: Chat,
    exact: true,
  },
  {
    path: '/users',
    component: UsersTable,
    exact: true,
    requiredRole: 'Admin',
  },
  {
    path: '/files',
    component: FilesTable,
    exact: true,
    requiredRole: 'Admin',
  },
  {
    path: '/feedbacks',
    component: FeedbacksTable,
    exact: true,
    requiredRole: 'Admin',
  },
  {
    path: '/',
    component: redirect('~landing'),
  },
  {
    path: '*',
    component: redirect('/home'),
  },
] as Array<EnhancedRouteProps>;
