import React from 'react';
import './Login.styles.scss';
import { Header, Form, Button, Segment } from 'semantic-ui-react';
import formDataToObject from 'utils/formData';
import api from 'api';
import credentialsService from 'services/credentialsService';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const Login: React.FC = () => {
  const history = useHistory();

  const onHandleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const loginObject = formDataToObject(
      new FormData(event.target as HTMLFormElement),
    );
    try {
      const { data } = await api.auth.login(loginObject);
      credentialsService.saveAuthBody(data);
      history.push('/');
    } catch (error) {
      toast.error('Invalid email or password.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  return (
    <div className="container">
      <Segment padded="very" raised className="section-container">
        <Header as="h1" textAlign="center">
          Login to your account
        </Header>
        <Form size="large" className="form-container" onSubmit={onHandleSubmit}>
          <Form.Input
            label="Email"
            name="email"
            placeholder="user@example.com"
            fluid
            icon="user"
            iconPosition="left"
            autoFocus
            required
          />
          <Form.Input
            label="Password"
            name="password"
            type="password"
            placeholder="Password"
            fluid
            icon="lock"
            iconPosition="left"
            required
          />
          <Button fluid size="large">
            Login
          </Button>
        </Form>
      </Segment>
    </div>
  );
};

export default Login;
