import auth from './auth';
import users from './users';
import files from './files';
import chatHistory from './chat-history';

export default {
  auth,
  users,
  files,
  chatHistory,
};
