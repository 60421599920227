import React from 'react';
import './Landing.styles.scss';

import { Header, Segment } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import QuestionForm from 'components/QuestionForm';

type LandingProps = {};

const Landing: React.FC<LandingProps> = () => {
  const history = useHistory();

  return (
    <div className="landing-container">
      <Segment padded="very" raised className="section-container">
        <Header as="h1" textAlign="center">
          Legal GPT
        </Header>
        <QuestionForm
          onSubmit={({ chatHistory }) => {
            history.push(`/home/chat-history/${chatHistory._id}`);
          }}
        />
      </Segment>
    </div>
  );
};

export default Landing;
