import React, { useState } from 'react';
import './QuestionForm.styles.scss';
import { Button, Form, Loader } from 'semantic-ui-react';
import api from 'api';
import { Question } from 'models/Question';
import { ChatHistory } from 'models/ChatHistory';
import { toast } from 'react-toastify';
import {
  CHAT_MODELS,
  CHAT_MODEL_LIMITS,
  DEFAULT_CHAT_MODEL,
} from 'constants/chat-models';
import { SECTORS, Sectors } from 'constants/sectors';

type QuestionFormProps = {
  onSubmit: (data: { question: Question; chatHistory: ChatHistory }) => any;
  chatHistoryId?: string;
  defaultSector?: Sectors;
};

const QuestionForm: React.FC<QuestionFormProps> = ({
  onSubmit,
  chatHistoryId,
  defaultSector = 'general legislations',
}) => {
  const [isAnswering, setIsAnswering] = useState(false);
  const [question, setQuestion] = useState('');
  const [chatModel, setChatModel] = useState<string>(DEFAULT_CHAT_MODEL);
  const [sector, setSector] = useState(defaultSector);

  const onHandleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      setIsAnswering(true);
      const { data } = await api.chatHistory.questions.create({
        chatModel,
        question,
        chatHistoryId,
        sector,
      });

      setIsAnswering(false);
      setQuestion('');

      onSubmit(data);
    } catch (error) {
      toast.error('Failed asking question.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
      });
      setIsAnswering(false);
    }
  };

  return (
    <Form size="large" className="form-container" onSubmit={onHandleSubmit}>
      {isAnswering ? <Loader active /> : null}
      <Form.TextArea
        label="Question"
        name="question"
        placeholder="Ask anything.."
        autoFocus
        required
        disabled={isAnswering}
        value={question}
        onChange={(_, data) => setQuestion(data.value?.toString())}
      />
      <Form.Select
        options={[...SECTORS]}
        value={sector}
        label="Selected question sector"
        name="sector"
        onChange={(_, data) => setSector(data.value as Sectors)}
      ></Form.Select>
      <Form.Select
        options={CHAT_MODELS.map((model) => ({
          value: model,
          text: `${model} (${CHAT_MODEL_LIMITS[model]})`,
          key: model,
        }))}
        value={chatModel}
        label="Selected chat model"
        name="chatModel"
        onChange={(_, data) => setChatModel(data.value as string)}
      ></Form.Select>
      <Button fluid size="large" disabled={isAnswering}>
        Submit
      </Button>
    </Form>
  );
};

export default QuestionForm;
