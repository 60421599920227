import { Feedback } from 'models/Feedback';
import React, { useEffect } from 'react';
import { Header, Segment, Table, Pagination, Loader } from 'semantic-ui-react';
import usePagination from 'hooks/usePagination';
import api from 'api';
import DatePreview from 'components/DatePreview';
import { Link } from 'react-router-dom';
import './FeedbacksTable.styles.scss';

const FeedbacksTable = () => {
  const { items, loadItems, totalPages, currentPage } = usePagination<Feedback>(
    api.chatHistory.questions.getFeedbacks,
    {
      clearOnLoad: false,
    },
  );

  const columns = ['Question ID', 'Feedback', 'Is positive', 'Created at'];

  useEffect(() => {
    loadItems(1);
  }, [loadItems]);

  if (!items) {
    return <Loader active />;
  }

  return (
    <Segment>
      <Header>Feedbacks</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            {columns.map((columnName: string) => (
              <Table.HeaderCell>{columnName}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((feedback: Feedback) => {
            const fb: Feedback = feedback;
            return (
              <Table.Row key={feedback._id}>
                <Table.Cell>
                  <Link
                    to={{
                      pathname: `chat-history/${feedback.question?.chatHistoryId}`,
                      state: fb,
                    }}
                  >
                    {feedback.questionId}
                  </Link>
                </Table.Cell>
                <Table.Cell className={feedback.isPositive ? 'isPositive' : ''}>
                  {feedback.isPositive
                    ? 'User has not provided feedback'
                    : feedback.feedback}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {feedback.isPositive ? 'Yes' : 'No'}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <DatePreview>{feedback.createdAt}</DatePreview>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4">
              <Pagination
                floated="right"
                activePage={currentPage}
                onPageChange={(ev, { activePage }) => loadItems(activePage)}
                totalPages={totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

export default FeedbacksTable;
