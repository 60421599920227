import React from 'react';
import './DatePreview.styles.scss';

type DatePreviewProps = {
  children: string | Date;
};

const DatePreview: React.FC<DatePreviewProps> = (props) => {
  const { children } = props;

  return <React.Fragment>{new Date(children).toLocaleString()}</React.Fragment>;
};

export default DatePreview;
