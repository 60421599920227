import React, { useState } from 'react';
import {
  Form,
  Button,
  Icon,
  Modal,
  Header,
  Item,
  TextArea,
  SemanticICONS,
} from 'semantic-ui-react';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import chatHistory from 'api/chat-history';
import { FeedbackBody } from 'models/FeedbackBody';
import { Question } from 'models/Question';
import { Feedback } from 'models/Feedback';
import './FeedbackForm.styles.scss';

type RatingButtonProps = {
  input: any;
  rating: boolean;
  setRating: (value: boolean) => void;
  active: number;
  setActive: (value: number) => void;
  type: string;
  text: string;
  iconName: SemanticICONS;
  noFeedback?: boolean;
};

type FeedbackFormProps = {
  questionId?: string;
  onSubmit?: (value: Question[]) => void;
  items?: Question[];
  feedback?: Feedback;
};

const RatingButton = (props: RatingButtonProps) => {
  const {
    input,
    rating,
    setRating,
    active,
    setActive,
    type,
    text,
    noFeedback,
    iconName,
  } = props;

  const classes = classNames([
    'lgu-feedback-form__rating__button',
    `lgu-feedback-form__rating__button${type}`,
    { 'lgu-feedback-form__rating__button--has-pointer': noFeedback },
  ]);

  return (
    <button
      onClick={() => {
        input.onChange(rating);
        setRating(rating);
      }}
      onMouseOver={() => setActive(active)}
      onMouseLeave={() => setActive(0)}
      type="button"
      className={classes}
    >
      <Icon name={iconName} size="big" />
      {text}
    </button>
  );
};

const FeedbackForm: React.FC<FeedbackFormProps> = ({
  questionId,
  onSubmit,
  items,
  feedback,
}) => {
  const classes = classNames(['lgu-feedback-form']);

  const [rating, setRating] = useState(null);
  const [active, setActive] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const onHandleSubmit = async (values: FeedbackBody) => {
    try {
      const { data } = await chatHistory.questions.createFeedback(
        values,
        questionId,
      );

      setIsOpen(false);
      const newItems = items.map((item, i) =>
        i ===
        items.findIndex((e: Question) => {
          return e._id === questionId;
        })
          ? { ...item, feedback: { ...data } }
          : item,
      );
      onSubmit(newItems);
    } catch (error) {
      console.error(error);
    }
  };

  const validate = (values: any) => {
    const error: { msg: string | undefined } = { msg: undefined };
    if (
      (!values.feedback && !values.isPositive) ||
      typeof values.isPositive !== 'boolean'
    ) {
      error.msg = 'required';
    }
    return error;
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      trigger={
        <Button
          primary
          size="small"
          floated="right"
          onClick={() => setIsOpen(true)}
        >
          {!feedback ? 'Give Feedback' : 'View Feedback'}
        </Button>
      }
      size="tiny"
      closeIcon
      className={classes}
    >
      <Modal.Header>Give us your feedback</Modal.Header>
      <Modal.Content>
        <FinalForm onSubmit={onHandleSubmit} validate={validate}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Header as="h4">How would you rate this answer?</Header>
              <Field name="isPositive">
                {({ input }) => (
                  <div className="lgu-feedback-form__rating">
                    <RatingButton
                      input={input}
                      rating={true}
                      setRating={setRating}
                      active={1}
                      setActive={setActive}
                      type={
                        !feedback
                          ? active === 1 || rating
                            ? '--positive'
                            : ''
                          : feedback.isPositive && '--positive'
                      }
                      iconName="thumbs up outline"
                      text="Positive"
                      noFeedback={!feedback}
                    />
                    <RatingButton
                      input={input}
                      rating={false}
                      setRating={setRating}
                      active={2}
                      setActive={setActive}
                      type={
                        !feedback
                          ? active === 2 || rating === false
                            ? '--negative'
                            : ''
                          : !feedback.isPositive && '--negative'
                      }
                      iconName="thumbs down outline"
                      text="Negative"
                      noFeedback={!feedback}
                    />
                  </div>
                )}
              </Field>
              <Header as="h4">Feedback</Header>
              {!feedback ? (
                <>
                  <Field name="feedback">
                    {({ input }) => (
                      <TextArea
                        placeholder="Write something here.."
                        {...input}
                      />
                    )}
                  </Field>
                  <Button primary type="submit" fluid>
                    Submit
                  </Button>
                </>
              ) : (
                <Item>
                  {!feedback.feedback
                    ? 'You did not provide feedback for this answer'
                    : feedback.feedback}
                </Item>
              )}
            </Form>
          )}
        </FinalForm>
      </Modal.Content>
    </Modal>
  );
};

export default FeedbackForm;
