import React, { useState, useCallback } from 'react';
import { Form, Button, Modal } from 'semantic-ui-react';
import './UserModal.styles.scss';
import { capitalize } from 'utils/string';
import { User } from 'models/User';

type UserModalProps = {
  onSubmit: (updated: Partial<User>, user?: User) => any;
  action: 'create' | 'update';
  user?: User;
};

const UserModal: React.FC<UserModalProps> = ({
  onSubmit = () => {},
  children,
  user,
  action,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(user?.name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [password, setPassword] = useState(user?.password || '');

  const handleSubmit = useCallback(
    (ev) => {
      onSubmit(
        {
          name,
          email,
          password,
        },
        user,
      );
      setIsOpen(false);
    },
    [onSubmit, name, email, password, user],
  );

  const onClose = useCallback(
    (ev) => {
      ev.stopPropagation();
      setIsOpen(false);
    },
    [setIsOpen],
  );
  const onOpen = useCallback(
    (ev) => {
      ev.stopPropagation();
      setIsOpen(true);
    },
    [setIsOpen],
  );

  return (
    <Modal
      open={isOpen}
      trigger={React.cloneElement(children as any, { onClick: onOpen })}
      onClose={onClose}
    >
      <Modal.Header>{capitalize(action)} User</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit}>
          <Form.Input
            fluid
            label="Name"
            required
            value={name}
            onChange={(e, { value }) => setName(value)}
          />
          <Form.Input
            fluid
            label="Email"
            required
            value={email}
            onChange={(e, { value }) => setEmail(value)}
          />
          <Form.Input
            fluid
            label="Password"
            type="password"
            required={action === 'create'}
            value={password}
            onChange={(e, { value }) => setPassword(value)}
          />
          <Button type="submit">{capitalize(action)}</Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default UserModal;
