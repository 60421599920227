import Login from 'pages/Login';
import { EnhancedRouteProps } from './routes/Enhanced/Enhanced.route';
import redirect from 'router/modules/redirect';
import HomeRouter from 'router/subrouters/Home';

export default [
  {
    path: '/login',
    onlyPublic: true,
    component: Login,
  },
  {
    path: '/home',
    component: HomeRouter,
    exact: false,
    authorized: true,
  },
  {
    path: '*',
    component: redirect('/home'),
  },
] as Array<EnhancedRouteProps>;
