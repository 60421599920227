import React, { useCallback, useState, useEffect } from 'react';
import {
  Table,
  Pagination,
  Loader,
  Button,
  Input,
  Segment,
  Header,
} from 'semantic-ui-react';
import './UsersTable.styles.scss';
import usePagination from 'hooks/usePagination';
import api from 'api';
import ConfirmPopup from 'components/ConfirmPopup';
import { toast } from 'react-toastify';
import { User } from 'models/User';
import UserModal from 'router/subrouters/Home/pages/UsersTable/components/UserModal';
import DatePreview from 'components/DatePreview';

type UsersTableProps = {};

const UsersTable: React.FC<UsersTableProps> = (props) => {
  const [searchInput, setSearchInput] = useState('');
  const { items, loadItems, setItems, currentPage, totalPages } =
    usePagination<User>(api.users.get, { clearOnLoad: false });

  useEffect(() => {
    loadItems(1, { $searchString: searchInput });
  }, [searchInput, loadItems]);

  const onCreate = useCallback(
    async (userData) => {
      const { data: user } = await api.users.create(userData);
      setItems((items) => [user, ...items]);
      toast.success('Successfully created user.');
    },
    [setItems],
  );

  const onUpdate = useCallback(
    async (updateData: Partial<User>, changedUser: User) => {
      const { data: user } = await api.users.updateOne(
        changedUser._id,
        updateData,
      );

      setItems((items) =>
        items.map((item) => (item._id === changedUser._id ? user : item)),
      );
      toast.success('Successfully updated user');
    },
    [setItems],
  );

  const onDelete = useCallback(
    async (user: User) => {
      await api.users.deleteOne(user._id);
      setItems((items) => items.filter((item) => item._id !== user._id));
      toast.success('Successfully deleted user.');
    },
    [setItems],
  );

  if (!items) {
    return <Loader active />;
  }

  return (
    <Segment>
      <Header>Users</Header>
      <Input
        focus
        placeholder="Search..."
        value={searchInput}
        onChange={(e, field) => setSearchInput(field.value)}
      />
      <UserModal onSubmit={onCreate} action="create">
        <Button floated="right">Create User</Button>
      </UserModal>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((user: User) => (
            <Table.Row key={user._id}>
              <Table.Cell>{user.name}</Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>
                <p>{user.role}</p>
              </Table.Cell>
              <Table.Cell>
                <DatePreview>{user.createdAt}</DatePreview>
              </Table.Cell>
              <Table.Cell>
                <UserModal action="update" onSubmit={onUpdate} user={user}>
                  <Button icon="edit" color="blue"></Button>
                </UserModal>
                <ConfirmPopup
                  confirm={() => onDelete(user)}
                  text="Are you sure you want to remove this user?"
                  buttonText="Delete"
                >
                  <Button icon="trash" color="red"></Button>
                </ConfirmPopup>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="5">
              <Pagination
                floated="right"
                activePage={currentPage}
                onPageChange={(ev, { activePage }) => loadItems(activePage)}
                totalPages={totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Segment>
  );
};

export default UsersTable;
