import React, { useState, useCallback, useRef } from 'react';
import { Form, Button, Modal } from 'semantic-ui-react';
import { File } from 'models/File';
import { capitalize } from 'utils/string';
import classNames from 'classnames';
import { SECTORS } from 'constants/sectors';

import './FileModal.styles.scss';

type FileModalProps = {
  onSubmit: (body: FormData, file?: File, sector?: string) => any;
  action: 'create' | 'update';
  file?: File;
};

const FileModal: React.FC<FileModalProps> = ({
  onSubmit = () => {},
  children,
  file,
  action,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sector, setSector] = useState(file?.sector || 'general legislations');
  const [fileName, setFileName] = useState(file?.name || '');

  const fileInputRef = useRef<HTMLInputElement>(null);

  const classes = classNames(['lgu-file-modal']);

  const handleSubmit = useCallback(
    (ev) => {
      const formData = new FormData(ev.target);
      formData.append('sector', sector);

      if (fileInputRef.current?.files.length > 1) {
        const files = Array.from(fileInputRef.current.files);
        files.forEach((file) => {
          formData.append('files', file);
        });
        formData.delete('file');
      }

      onSubmit(formData, file);
      setIsOpen(false);
    },
    [file, onSubmit, sector],
  );

  const onOpen = useCallback(
    (ev) => {
      ev.stopPropagation();
      setIsOpen(true);
    },
    [setIsOpen],
  );

  return (
    <Modal
      open={isOpen}
      trigger={React.cloneElement(children as any, { onClick: onOpen })}
      onClose={() => setIsOpen(false)}
      size="tiny"
      className={classes}
    >
      <Modal.Header>{capitalize(action)} File</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit} className="lgu-file-modal__form">
          <Form.Field className="lgu-file-modal__form__file-input">
            <Button
              icon="file"
              labelPosition="left"
              type="button"
              size="large"
              content={fileName || 'Choose file'}
              onClick={() => fileInputRef.current?.click()}
            />
            <input
              ref={fileInputRef}
              type="file"
              required
              hidden
              multiple
              onChange={(ev) => {
                const fileNames = Array.from(ev.target.files)
                  .map((file) => file.name)
                  .join(', ');
                setFileName(fileNames);
              }}
              name="file"
            />
          </Form.Field>

          {action === 'create' && (
            <Form.Select
              label="Sector"
              required
              options={[...SECTORS]}
              name="sector"
              value={sector}
              onChange={(_, data) => setSector(data.value as string)}
            />
          )}

          <Button primary fluid type="submit">
            {capitalize(action)}
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default FileModal;
